<template>
  <div class="mapress">
    <van-nav-bar left-arrow>
      <template #left>
        <img
          style="width: 18px; height: 18px"
          src="../../../assets/black_return_view_bg.png"
          @click="gotlink"
          alt
        />
      </template>
      <template #title>
        <span style="color: black; font-size: 18px">地址搜索</span>
      </template>
      <!-- <template #right>
        <van-button type="info" size="mini" @click="showPopup">使用当前图标位置</van-button>
      </template>-->
    </van-nav-bar>
    <div class="search_all_view">
      <div class="search_view">
        <img src="../../../assets/gray_address_bg.png" alt />
        <input v-model="data" type="text" placeholder="请输入收货地址" @input="toSearch" />
      </div>
    </div>
    <div class="con-box con-map">
      <div class="mapbox">
        <div class="map" id="container"></div>
        <div class="sign"></div>
      </div>
    </div>
    <div class="bot-ress">
      <div
        v-for="(item, index) in address"
        :key="index"
        class="ress"
        @click="chooseRess(item.name, item.geometry.location)"
      >
        <div class="title">{{ item.name }}</div>
        <div class="address">{{ item.vicinity }}</div>
      </div>
    </div>
    <!-- <van-popup v-model="show" position="bottom">
      <van-form @submit="onSubmit">
        <van-field
          v-model="ress"
          name="收货地址"
          label="收货地址"
          placeholder="收货地址"
          :rules="[{ required: true, message: '请填写收货地址' }]"
        />
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </van-popup>-->

    <!-- <iframe
      id="geoPage"
      width="0"
      height="0"
      frameborder="0"
      style="display: none"
      scrolling="no"
      src="https://apis.map.qq.com/tools/geolocation?key=3AABZ-RG3LX-2LT4M-ZPY3C-OIYVV-RFFWW&referer=shengxian"
    ></iframe>-->
  </div>
</template>

<script>
import {
  suggestion,
  tencentSearch,
  tencentExplore,
  googleLocation,
  googleSearch
} from "@/service/api/index.js";
export default {
  data() {
    return {
      data: "",
      lat: "",
      lng: "",
      address: [],
      code: "",
      countryCode: "",
      show: false,
      ress: "",
      map: "",
      marker: "",
      position: ""
      // isMapInit:true,
    };
  },
  created() {},
  mounted() {
    this.code = this.$route.query.code;
    this.countryCode = this.$route.query.countryCode;
    this.location();
  },

  methods: {
    location() {
      // 测试禁用ip定位
      // googleLocation({ considerIp: false }).then(data => {
      googleLocation().then(data => {
        // 定位点位
        this.position = { lat: data.location.lat, lng: data.location.lng };
        // 初始化地图
        this.map = new google.maps.Map(document.getElementById("container"), {
          center: this.position,
          zoom: 16
        });
        // 初始化并渲染maker图标
        this.marker = new google.maps.Marker({
          position: this.position,
          map: this.map
        });
        // 监听地图点击事件
        let that = this;
        google.maps.event.addListener(this.map, "click", function(data) {
          let center = data.latLng;
          that.marker.setPosition(center);
          that.nearby(center);
        });
      });
    },
    //搜索附近
    nearby(data) {
      let center = data.toJSON();
      let date = {
        keyword: "",
        lat: center.lat,
        lng: center.lng
      };
      googleSearch(date).then(res => {
        this.address = res.data;
      });
    },
    // initMap() {
    //   var that = this;
    //   //定义地图中心点坐标
    //   var center = new qq.maps.LatLng(this.lat, this.lng);
    //   //定义map变量，调用 TMap.Map() 构造函数创建地图
    //   var map = new qq.maps.Map(document.getElementById("container"), {
    //     center: center, //设置地图中心点坐标
    //     zoom: 17 //设置地图缩放级别
    //   });
    //   var marker = new qq.maps.Marker({
    //     position: center,
    //     map: map
    //   });
    //   //添加监听事件  获取鼠标点击事件
    //   qq.maps.event.addListener(map, "click", function(event) {
    //     that.lat = event.latLng.lat;
    //     that.lng = event.latLng.lng;
    //     that.doexplre(event.latLng.lat, event.latLng.lng);
    //     marker.setPosition(event.latLng);
    //   });
    // },
    toSearch() {
      let date = {
        keyword: this.data,
        lat: this.position.lat,
        lng: this.position.lng
      };
      googleSearch(date).then(res => {
        this.address = res.data;
      });
      // tencentSearch({
      //   lat: this.lat,
      //   lng: this.lng,
      //   keyword: this.data,
      //   radius: "500"
      // }).then(res => {
      //   console.log(res);
      //   this.address = res.data;
      // });
    },
    // doexplre(lat, lng) {
    //   tencentExplore({
    //     lat: lat,
    //     lng: lng,
    //     radius: "500"
    //   }).then(res => {
    //     this.address = res.data;
    //   });
    // },
    // showPopup() {
    //   this.$router.replace({
    //     path: this.$route.query.form,
    //     query: {
    //       ress: "用户手动输入位置",
    //       lat: this.lat,
    //       lng: this.lng,
    //       code: this.code,
    //       countryCode: this.countryCode,
    //       addr: this.$route.query.addr
    //     }
    //   });
    // },
    // onSubmit() {
    //   this.$router.replace({
    //     path: this.$route.query.form,
    //     query: {
    //       ress: this.ress,
    //       lat: this.lat,
    //       lng: this.lng,
    //       code: this.code,
    //       countryCode: this.countryCode,
    //       addr: this.$route.query.addr
    //     }
    //   });
    // },
    chooseRess(data, location) {
      this.$router.replace({
        path: this.$route.query.form,
        query: {
          ress: data,
          lat: location.lat,
          lng: location.lng,
          code: this.code,
          countryCode: this.countryCode,
          addr: this.$route.query.addr
        }
      });
    },
    gotlink() {
      // console.log(this.$route.query.form)
      // return
      this.$router.replace({
        path: this.$route.query.form,
        query: {
          addr: this.$route.query.addr
        }
      });
    }
  }
};
</script>

<style scoped>
/* /deep/ #app{
  height: 100%;
} */
</style>
<style lang="less" scoped>
.mapress {
  display: flex;
  flex-direction: column;
  // position: fixed;
  position: relative;
  height: 100%;
  width: 100%;
}
.con-box {
  .mapbox {
    width: 100%;
    height: 100%;
    padding: 10px 0;
    background: #eceeee;
    .map {
      width: 100%;
      height: 236px;
    }
  }
}
.search_all_view {
  width: 100%;
  background: white;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search_view {
  width: 100%;
  height: 30px;
  border-radius: 20px;
  border: 1px solid #00b05a;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 10px;
  box-sizing: border-box;
}
.search_view img {
  width: 16px;
  height: 16px;
  margin-left: 7px;
}
.search_view input {
  flex: 1;
  margin-left: 4px;
  border: none;
  background: none;
  font-size: 14px;
}
.search_view input::-webkit-input-placeholder {
  font-size: 12px;
}
.bot-ress {
  flex: 1;
  overflow-y: scroll;
  .ress {
    width: 100%;
    background: white;
    border-bottom: 1px #ccc solid;
    padding: 10px;
    box-sizing: border-box;
    .title {
      font-size: 12px;
    }
    .address {
      font-size: 10px;
      color: #ccc;
    }
  }
}
</style>
